import { ExampleCostInsightsClient } from '@backstage/plugin-cost-insights';

class ExampleEntity {
}

export class CostInsightsClient extends ExampleCostInsightsClient {
  async getExampleData(): Promise<ExampleEntity[]> {
    return [
      {
        name: 'Backstage',
        description: 'Backstage is an open platform for building developer portals',
        owner: 'Spotify',
        kind: 'software',
        lifecycle: 'production',
        url: 'https://backstage.io',
        system: 'backstage',
        team: 'tools',
        cost: 100,
        change: 0.1,
      },
      {
        name: 'Spotify',
        description: 'Spotify is a digital music service that gives you access to millions of songs',
        owner: 'Spotify',
        kind: 'software',
        lifecycle: 'production',
        url: 'https://spotify.com',
        system: 'spotify',
        team: 'tools',
        cost: 100,
        change: 0.1,
      },
    ];
  }
}