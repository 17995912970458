import {
    TechRadarApi,
    TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

export class TechRadarClient implements TechRadarApi {
    // @ts-ignore
    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
        // const data = await fetch('https://mydata.json').then(res => res.json());
        return {
            quadrants: [
                {
                    id: 'tools',
                    name: 'Tools',
                },
                {
                    id: 'languages',
                    name: 'Languages & Frameworks',
                },
                {
                    id: 'platforms',
                    name: 'Platforms',
                },
                {
                    id: 'techniques',
                    name: 'Techniques',
                }
            ], rings: [
                {
                    id: 'adopt',
                    name: 'Adopt',
                    color: '#388e3c',
                },
                {
                    id: 'trial',
                    name: 'Trial',
                    color: '#f57c00',
                },
                {
                    id: 'assess',
                    name: 'Assess',
                    color: '#fbc02d',
                },
                {
                    id: 'hold',
                    name: 'Hold',
                    color: '#e53935',
                }
            ],
            entries: [
                {
                    key: 'golang',
                    id: '1',
                    title: 'Golang',
                    quadrant: 'languages',
                    timeline: [
                        {
                            date: new Date('2022-04-01'),
                            ringId: 'adopt',
                            moved: 1,
                        },
                        {
                            date: new Date('2022-01-01'),
                            ringId: 'trial',
                            moved: 1,
                        },
                    ],
                },
                {
                    key: 'php',
                    id: '2',
                    title: 'PHP',
                    quadrant: 'languages',
                    timeline: [
                        {
                            date: new Date('2022-04-01'),
                            ringId: 'hold',
                        },
                        {
                            date: new Date('2022-01-01'),
                            ringId: 'adopt',
                        },
                    ],
                },
                {
                    key: 'wordpress',
                    id: '3',
                    title: 'Wordpress',
                    quadrant: 'languages',
                    timeline: [
                        {
                            date: new Date('2022-04-01'),
                            ringId: 'hold',
                        },
                        {
                            date: new Date('2022-01-01'),
                            ringId: 'adopt',
                        },
                    ],
                }
            ]
        }
        // return {
        //     ...data,
        //     // @ts-ignore
        //     entries: data.entries.map(entry => ({
        //         ...entry,
        //         // @ts-ignore
        //         timeline: entry.timeline.map(timeline => ({
        //             ...timeline,
        //             date: new Date(timeline.date),
        //         })),
        //     })),
        // };
    }
}